<template>
  <LocaleLink
    v-if="content.link"
    :link="content.link"
    class="link--hover flex flex-col flex-nowrap bg-white p-space2 lg:flex-row"
    @click="trackLinkClick()"
  >
    <div class="mb-space h-[70px] w-[70px] flex-shrink-0 lg:mb-0 lg:mr-space2">
      <IconsTheIcon v-if="content.image" :icon="content.image.fields" />
      <ImagesNuxtImg
        v-else-if="content.image.fields.image.fields.file"
        :url="content.image.fields.image.fields.file.url"
        :lazy="false"
        class="h-full w-full object-contain"
        :alt="content.image.fields.alt ? content.image.fields.alt : ''"
      />
    </div>

    <div>
      <MarkdownTheMarkdown v-if="content.content" :source="content.content" />
      <LinksArrowLink
        :arrow-link="{
          type: 'Large Blue',
          linkUrl: content.link,
          text: content.linkText,
        }"
      />
    </div>
  </LocaleLink>
  <div v-else class="flex flex-col flex-nowrap bg-white p-space2 lg:flex-row">
    <div
      class="mb-space h-[60px] w-[60px] flex-shrink-0 lg:mb-0 lg:mr-space2 lg:h-[100px] lg:w-[100px]"
    >
      <IconsTheIcon v-if="content.image" :icon="content.image.fields" />
      <ImagesNuxtImg
        v-else-if="content.image.fields.image.fields.file"
        :url="content.image.fields.image.fields.file.url"
        :lazy="false"
        class="h-full w-full object-contain"
        :alt="content.image.fields.alt ? content.image.fields.alt : ''"
      />
    </div>

    <div>
      <MarkdownTheMarkdown v-if="content.content" :source="content.content" />
    </div>
  </div>
</template>
<script setup>
  import LocaleLink from '../links/LocaleLink.vue';

  const { analyticsTrack } = useAnalytics();

  const props = defineProps({
    content: {
      type: Object,
      required: true,
    },
    cardId: {
      type: String,
      required: false,
      default: '',
    },
  });

  const trackLinkClick = () => {
    analyticsTrack('Card Click', {
      tracking_id: props.content.trackingId
        ? props.content.trackingId
        : `${props.content.internalName.replaceAll(' ', '_')}-${props.cardId}`,
      link: props.content.link,
      session_id: sessionStorage.getItem('_session_id') || '',
      type: props.content.type,
    });
  };
</script>
<style lang="scss" scoped>
  .card--simple-image-left {
    @apply mb-1 h-full md:mb-0 lg:mb-1/2;
    :deep(img) {
      @apply m-0 h-full w-full object-contain object-top;
    }
  }
  .link--hover {
    @screen lg {
      transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.1s;
      transition-property: all;

      &:hover {
        @apply z-10;
        transform: scale(1.1);
      }
    }
  }
</style>
